import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookie from 'vue-cookie'
// import LocalStorage from 'local-storage'
import login from './../views/login'
import desk from './../views/desk'
import error from './../layout/error'
import tool from '@/tool'
import bank from './bank'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      auth: false
    }
  },
  {
    path: '/desk',
    name: 'desk',
    component: desk,
    meta: {
      auth: false
    }
  },
  {
    path: '*',
    name: 'error',
    component: error,
    meta: {
      auth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  /* eslint-disable */
  base: process.env.BASE_URL,
  /* eslint-disable */
  routes
})

// 在路由切换时清除所有未完成的请求
Vue.Cancel = []
router.beforeEach((to, from, next) => {
  if (window.axiosPromiseArr) {
    window.axiosPromiseArr.forEach((ele, index) => {
      ele.cancel()
      delete window.axiosPromiseArr[index]
    })
  }

  var auth = true
  if (typeof to.meta.auth != 'undefined') {
    auth = to.meta.auth
  }
  if (!auth) {
    next()
  }
  else if (auth) {

    if (VueCookie.get('role') && !Vue.local.get('navData')) { //
      api.systemBase
        .postUserMenu({
          roleId: tool.getRoleId(VueCookie.get('role')),
          type: '1'
        })
        .then((res) => {
          Vue.local.set('navData', res.data || [], 1000 * 60 * 60 * 24 * 31)
          if (tool.checkButton(to.name)) {
            next()
          } else {
            next('/forbidden')
          }
        })
    } else {
      if (!VueCookie.get('role')) {
        next('/login?redirect=' + encodeURIComponent(to.path))
      }
      else if (tool.checkButton(to.name)) {
        next()
      } else {
        next('/forbidden')
      }
    }

  }

})
// 过滤用户路由
// const getRouter = (router, code, ability = '') => {
//   const nameList = (ability || '').split(',').filter(item => {
//     return item.indexOf(code) === 0
//   }).map(item => {
//     return item.substring(code.length + 1)
//   })

//   const filterTree = (list, nameList) => {
//     return list.filter(item => {
//       return nameList.indexOf(item.name) !== -1 || !item.name
//     }).map(item => {
//       return {
//         ...item,
//         children: item.children ? filterTree(item.children, nameList) : []
//       }
//     })
//   }
//   return filterTree(router, nameList)
// }

//
// if (role === 'BANKUSER' || role === 'SUPERUSER') {
//   router.addRoutes(getRouter(bank, getConfig().name, (LocalStorage.get('ability') || {}).value))
// } else if (role === 'TENANTUSER' || role === 'USER') {
// console.log('LocalStorage.get(\'ability\')',LocalStorage.get('ability'))
//   router.addRoutes(getRouter(tenant, getConfig().name, (LocalStorage.get('ability') || {}).value))
// }

router.addRoutes(bank)
export default router
