<template>
  <div class="g-box g-error-box">
    404
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'error',
  components: {},
  directives: {},
  filters: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
})
</script>

<style lang="scss" scoped>
  @import "./../assets/css/global-variable.scss";
</style>

<style>
</style>
