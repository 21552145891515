// 获取当前浏览器信息
const getBrowserInfo = () => {
  let type = ''
  let version = ''
  const userAgent = navigator.userAgent
  const isOpera = userAgent.indexOf('Opera') > -1

  // 谷歌
  if (userAgent.indexOf('Chrome') > -1) type = 'Chrome'

  // 火狐
  if (userAgent.indexOf('Firefox') > -1) type = 'Firefox'

  // ie
  if (window.ActiveXObject || 'ActiveXObject' in window) {
    type = 'IE'
    const reIE = new RegExp('MSIE (\\d+\\.\\d+);')
    reIE.test(userAgent)
    const fIEVersion = parseFloat(RegExp.$1)
    if (userAgent.indexOf('MSIE 6.0') !== -1) {
      version = '6'
    } else if (fIEVersion === 7) {
      version = '7'
    } else if (fIEVersion === 8) {
      version = '8'
    } else if (fIEVersion === 9) {
      version = '9'
    } else if (fIEVersion === 10) {
      version = '10'
    } else if (userAgent.toLowerCase().match(/rv:([\d.]+)\) like gecko/)) {
      version = '11'
    } else {
      version = '0'
    }
  }

  // Opera
  if (isOpera) type = 'Opera'

  // Safari
  if (userAgent.indexOf('Safari') > -1) type = 'Safari'
  return {
    type,
    version
  }
}

export default getBrowserInfo
