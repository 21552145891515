const recursionFind = (tree = [], key = 'value', value = '') => {
  for (let i = 0; i < tree.length; i++) {
    const item = tree[i]
    if (item[key] === value) {
      return item
    } else {
      if ((item.node || []).length) {
        const _value = recursionFind(item.node, key, value)
        if (_value) return _value
      }
    }
  }
}

export default recursionFind
