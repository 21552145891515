import ajax from '@/api/ajax'
import getConfig from '@/config'

export default {
  save(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/carbonInformation/saveCarbonInformation',
      method: 'post',
    })
  },

  remove(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/carbonInformation/deleteCarbonInformationById',
      method: 'post',
    })
  },

  update(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/carbonInformation/updateCarbonInformation',
      method: 'post',
    })
  },
  findById(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/carbonInformation/findCarbonInformationById',
      method: 'post',
    })
  },
  queryPage(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/carbonInformation/queryCarbonInformationPage',
      method: 'post',
    })
  },
  queryTypeTree(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/carbonInformationCategory/queryCarbonInformationCategoryList',
      method: 'post',
    })
  },
  updateStatusById(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/carbonInformation/updateInformationStatusById',
      method: 'post',
    })
  }
}
