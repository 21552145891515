<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script type="text/ecmascript-6">
import api from '@/api/index.js'
export default {
  data () {
    return {
      isLoading: true,
    }
  },
  name: 'app',
  watch: {
    $route () {
      // this.tool.checkLogin(() => {
      //   if (this.$route.name !== 'login') this.$router.push('/login')
      // })
    }
  },
  created () {
    this.tool.checkLogin(() => { // 没登录去登录 以后换成免登页面机制
      if (this.$route.name && this.$route.name !== 'login') {
        this.$router.replace('/login')
      }
    })
    api.systemBase.getAesKey().then(res => {
      console.log(res, '34')
      this.$store.commit('setUser', {
        ...this.$store.state.user,
        decodeKey: res.data
      })
      this.isLoading = false
    })
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import "./assets/css/element-variables.scss";
@import "./assets/font/icon-font/iconfont.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "./assets/css/global-variable";
@import "./assets/css/theme";
@import "./assets/css/index";
#app {
  width: 100%;
  height: 100%;
}
</style>
