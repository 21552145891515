const baseUrl = 'https://cms.carbonebook.com/tantan-portal'

const config = {
  port: 8011,
  name: 'ZEUS',
  room: '', // 静态服务地址
  pathZeus: 'https://webadmin.carbonebook.com/tantan-door-admin',
  serverApi: baseUrl + '/portal-api',
  uploadUrl: baseUrl + '/portal-api/upload/uploadFile',
  ftpUrl: 'https://media2.carbonebook.com',
  secretKey: 'richeninfo'
}
export default config
