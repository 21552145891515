import ajax from '@/api/ajax'
import getConfig from '@/config'

export default {
  queryPage (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/contactOurRecord/queryContactOurList',
      method: 'post',
    })
  }
}
