import ajax from '@/api/ajax'
import getConfig from '@/config'

export default {
  save (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/recruitmentInformation/saveRecruitmentInformation',
      method: 'post',
    })
  },

  remove (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/recruitmentInformation/deleteRecruitmentInformationById',
      method: 'post',
    })
  },

  update (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/recruitmentInformation/updateRecruitmentInformation',
      method: 'post',
    })
  },
  findById (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/recruitmentInformation/findRecruitmentInformationById',
      method: 'post',
    })
  },
  queryPage (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/recruitmentInformation/queryRecruitmentInformationPage',
      method: 'post',
    })
  },
  updateStatusById (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/recruitmentInformation/updateRecruitmentInformationStatusById',
      method: 'post',
    })
  }
}
