const Notification = (title, body) => {
  window.Notification.requestPermission(() => {
    const n = new window.Notification(title, {
      body: body
    })
    console.log('n', n)
  })
}

export default Notification
