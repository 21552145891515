<template>
  <div class="g-box g-m-nav-box">
    <el-menu
      ref="menu"
      :default-active="activePath"
      unique-opened
      @select="navActive"
      background-color="#206e55"
      text-color="#fff"
      active-text-color="#206e55"
      class="el-menu-vertical-demo"
      :collapse="!expand"
    >
      <template v-for="one in list">
        <el-submenu :index="one.path" :key="one.id" v-if="one.node.length">
          <template slot="title">
            <i :class="[one.icon, 'u-one-icon']"></i>
            <span slot="title" class="u-title" v-if="expand">
              {{ $t(`menu['${one.title}']`) }}</span
            >
          </template>
          <template v-for="two in one.node">
            <el-submenu :index="two.path" :key="two.id" v-if="two.node.length">
              <span slot="title" class="u-title">{{ two.title }}</span>
              <template v-for="three in two.node">
                <el-submenu
                  :index="three.path"
                  :key="three.id"
                  v-if="three.node.length"
                >
                  <span slot="title" class="u-title">{{ three.title }}</span>
                  <template v-for="four in three.node">
                    <el-submenu
                      :index="four.path"
                      :key="four.id"
                      v-if="four.node.length"
                    >
                      <span slot="title" class="u-title"
                        ><{{ four.title }}</span
                      >
                    </el-submenu>
                    <el-menu-item :index="four.path" :key="four.id" v-else>
                      <span slot="title" class="u-title">{{ four.title }}</span>
                    </el-menu-item>
                  </template>
                </el-submenu>
                <el-menu-item :index="three.path" :key="three.id" v-else>
                  <span slot="title" class="u-title">{{ three.title }}</span>
                </el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item :index="two.path" :key="two.id" v-else>
              <span slot="title" class="u-title">
                {{ $t(`menu['${two.title}']`) }}</span
              >
            </el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item :index="one.path" :key="one.id" v-else>
          <i :class="[one.icon, 'u-one-icon']"></i>
          <span slot="title" class="u-title">
            {{ $t(`menu['${one.title}']`) }}</span
          >
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import LocalStorage from 'local-storage'
import { getPathNavData } from './nav-data'
import VueCookie from 'vue-cookie'
import api from '@/api/index.js'

export function treearr2 (arr, remove) {
  var arr2 = []

  for (let i = arr.length - 1; i >= 0; i--) {
    if (remove && arr[i].disabled) {
      arr.splice(i, 1)
    } else if (arr[i].childrenList && arr[i].childrenList.length > 0) {
      treearr(arr[i].childrenList, remove)
    } else {
      delete arr[i].childrenList
    }
  }

  arr2 = arr
  return arr2
}

function getPathNavData2 (list) {
  return list
}

export default {
  name: 'm-nav',
  components: {},
  directives: {},
  filters: {},
  props: {
    expand: {
      type: Boolean
    }
  },
  data () {
    return {
      list: [],
      activePath: '',
      ability: [],
      filter: {
        navMap: {}
      }
    }
  },
  computed: {
  },
  watch: {
    $route () {
      this.list = getPathNavData2(this.$store.state.menuList)
      // this.activePath = ((this.tool.recursionFindProp(this.$store.state.menuList, 'name', this.$route.meta.cname || this.$route.name) || [{}]).find(item => item.show) || {}).path
    }
  },
  created () {
    this.ability = (LocalStorage.get('ability') || {}).value.split(',').map(item => {
      return {
        name: item.substring(item.indexOf('-') + 1),
        code: item.substring(0, item.indexOf('-'))
      }
    })
    this.list = getPathNavData2(this.$store.state.menuList)
    // this.activePath = ((this.tool.recursionFindProp(this.$store.state.menuList, 'name', this.$route.meta.cname || this.$route.name) || [{}]).find(item => item.show) || {}).path
    this.activePath = VueCookie.get('showMenu') || ''
  },
  mounted () {
    setTimeout(() => document.querySelector('body').classList.remove('s-create'))
  },
  methods: {
    abilityFilter (list) {
      return list.filter(item => item.show && item.type === 'nav').filter(item => {
        let show = false
        const code = ((this.tool.recursionFindProp(getPathNavData(this.$route.path), 'path', item.path) || []).reverse()[0] || []).code
        // const recording = this.ability.find(node => node.name === item.name && code === node.code)
        // if (recording && (recording || {}).code === code) show = true
        // if (item.code && item.node.find(data => (this.ability.find(node => node.name === data.name) || {}).code === item.code)) show = true
        // if (this.ability.map(node => node.name).indexOf(item.code) !== -1) show = true
        // if (this.ability.map(node => node.name).indexOf(code) !== -1) show = true
        if (code === 'ZEUS') show = true
        return show
      }).map(item => {
        const code = ((this.tool.recursionFindProp(getPathNavData(this.$route.path), 'path', item.path) || []).reverse()[0] || []).code
        let prefix = ''
        switch (code) {
          case 'ZEUS':
            prefix = this.config.pathZeus
            break
          default:
            prefix = ''
        }
        if (code === this.config.name || item.type === 'customizeNav') prefix = ''
        if (item.node) {
          const data = {
            id: String(item.id),
            ...item,
            path: prefix + item.path,
            node: this.abilityFilter(item.node)
          }
          this.filter.navMap[item.id] = data
          return data
        } else {
          this.filter.navMap[item.id] = item
          return item
        }
      })
    },
    init (list) {
      list.forEach(item => {
        if (item.node.filter(item => item.show).length) {
          return this.init(item.node)
        }
      })
    },
    recursionFind (tree = [], key = 'value', value = '') {
      for (let i = 0; i < tree.length; i++) {
        const item = tree[i]
        if (value.includes(item[key])) {
          return item
        } else {
          if ((item.node || []).length) {
            const _value = this.recursionFind(item.node, key, value)
            if (_value) return _value
          }
        }
      }
    },
    navActive (index, indexPath) {
      VueCookie.set('showMenu', index)
      // // this.toPath(this.filter.navMap[index].path)
      if (this.$refs.menu.openedMenus) {
        var a = this.$refs.menu.openedMenus

        if (!indexPath.includes(a[0])) {
          this.$refs.menu.close(this.$refs.menu.openedMenus[0])
        }
      }

      // alert(indexPath)
      this.toPath(index)
    },
    getActivePath (path) {
      const arr = (this.tool.recursionFindProp(getPathNavData(this.$route.path), 'path', path) || []).reverse()
      let record = ''
      arr.forEach(item => {
        if (item.show) record = item.path
      })
      return record
    },
    toPath (path) {
      if (path.indexOf('http') === 0) {
        document.querySelector('body').classList.add('s-destroy')
        setTimeout(() => {
          window.location.href = path
        })
      }
      if (path.indexOf('http') === -1) {
        this.$router.push(path)
      }
    }
  }
}
</script>
<style>
.g-m-nav-box .el-submenu__icon-arrow {
  color: #ffffff !important;
  margin-right: 10px;
}
</style>
<style lang="scss" scoped>
//@import "../../assets/css/global-variable";
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 208px;
  min-height: 400px;
  color: #ffffff;
}
.u-one-icon {
  /* margin-right: 10px; */
  font-size: 16px;
}
.el-menu-item {
  padding-right: 0;
  height: 45px;
  line-height: 45px;
  margin: 10px 0;
  transition: all ease 0s;
}
.el-menu-item i:not(.u-dot) {
  margin-left: 20px;
  height: 45px;
  line-height: 45px;
}
.el-menu-item i,
.el-submenu__title i {
  color: #fff;
}
.el-menu-item.is-active .u-title {
  padding: 10px 20px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.el-menu-item.is-active {
  padding-left: 0;
  margin-left: 20px;
  position: relative;
  background-color: #f7f8f7 !important;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  .u-title {
    padding-left: 0;
  }
  i {
    color: #206e55;
    margin-left: 0;
  }
  &::before,
  &::after {
    position: absolute;
    z-index: 99;
    content: "";
    width: 0;
    height: 0;
  }
  &::before {
    top: -8px;
    right: 0;
    border-bottom: 8px solid #f7f8f7;
    border-left: 8px solid transparent;
  }
  &::after {
    bottom: -8px;
    right: 0;
    border-right: 8px solid #f7f8f7;
    border-bottom: 8px solid transparent;
  }
}
// .el-submenu.is-active {
//   > .el-submenu__title {
//     .u-dot {
//       background: #ffffff;
//     }
//     .u-one-icon {
//       color: #ffffff;
//     }
//   }
// }

.el-submenu {
  &:nth-child(1) {
    .el-submenu__title {
      .u-one-icon,
      .u-title::before {
        background: url("~assets/img/menu/icon1.png") no-repeat center/20px 20px;
      }
    }
  }
  &:nth-child(2) {
    .el-submenu__title {
      .u-one-icon,
      .u-title::before {
        background: url("~assets/img/menu/icon2.png") no-repeat center/20px 20px;
      }
    }
  }
  &:nth-child(3) {
    .el-submenu__title {
      .u-one-icon,
      .u-title::before {
        background: url("~assets/img/menu/icon3.png") no-repeat center/20px 20px;
      }
    }
  }
  &:nth-child(4) {
    .el-submenu__title {
      .u-one-icon,
      .u-title::before {
        background: url("~assets/img/menu/icon4.png") no-repeat center/20px 20px;
      }
    }
  }
  &:nth-child(5) {
    .el-submenu__title {
      .u-one-icon,
      .u-title::before {
        background: url("~assets/img/menu/icon5.png") no-repeat center/20px 20px;
      }
    }
  }
  .el-submenu__title {
    i {
      /* margin-left: 50px; */
      display: inline-block;
      width: 40px;
      height: 40px;
    }
  }
  .el-menu-item {
    padding-left: 80px !important;
  }
  .el-menu-item.is-active {
    margin-left: 60px;
    padding-left: 20px !important;
  }
}
.el-submenu.is-opened .el-submenu__title {
  i {
    background-color: #277f64;
  }
  .iconfont {
    z-index: 20;
    position: relative;
  }
}

.el-submenu.is-opened .el-submenu__title .u-title {
  background: #277f64;
  padding: 0px 0px;
  position: relative;
  width: 80px;
  display: inline-block;
  line-height: 40px;
}
.el-submenu.is-opened .el-submenu__title .u-title:before {
  content: "";
  position: absolute;
  top: 0;
  left: -40px;
  width: 40px;
  height: 100%;
  background-color: #277f64;
}
.el-submenu.is-opened .el-submenu__title .u-title:after {
  content: "";
  position: absolute;
  top: 0;
  right: -40px;
  width: 40px;
  height: 100%;
  background-color: #277f64;
}

.el-submenu.is-opened .el-menu-item .u-title {
  position: relative;
}
.el-submenu.is-opened .el-menu-item .u-title:before {
  content: "";
  position: absolute !important;
  top: 0;
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  display: inline-block;
  left: -20px;
  top: 8px;
}

.el-submenu.is-opened .el-menu-item.is-active {
  left: -40px;
  padding-left: 60px !important;
  .u-title:before {
    background-color: #277f64;
    top: 16px;
  }

  &::before {
    top: -8px;
    right: 10px;
    border-bottom: 8px solid #f7f8f7;
    border-left: 8px solid transparent;
  }
  &::after {
    bottom: -8px;
    right: 10px;
    border-right: 8px solid #f7f8f7;
    border-bottom: 8px solid transparent;
  }
}
.u-title {
  font-size: 14px;
}
.u-dot {
  display: inline-block;
  width: 8px;
  border-radius: 50%;
  height: 8px;
  background: #f7f8f7;
  margin-right: 10px;
}
.g-m-nav-box {
  padding: 0px 0;
  width: 100%;
}
.el-menu--popup .el-menu-item.is-active,
.el-menu--popup .el-menu-item {
  margin-left: 20px !important;
}
.el-menu--popup .el-submenu .el-menu-item {
  padding-left: 20px !important;
}
</style>

<style>
.el-submenu .el-submenu__title {
  background-color: transparent !important;
}
</style>
