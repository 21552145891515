import ajax from './ajax'
import getConfig from './../config'
import tool from './../tool'
export default {
  // 上传图片
  uploadFile (data) {
    return ajax(data, {
      url: getConfig().uploadUrl,
      method: 'post',
    })
  },
  // 获取解密key
  getAesKey (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/login/getAesKey',
      method: 'post'
    })
  },
  // 获取登录校验
  postAuthSalt (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/login/getPublicKey',
      method: 'post'
    })
  },
  // 用户登录
  async postAuthLogin (data) {
    let encrypt
    let encrypted
    return new Promise(
      (resolve) => {
        tool
          .loadScript(getConfig().pathZeus + '/jsencrypt.js?' + new Date())
          .then((res) => {
            /* eslint-disable */
            encrypt = new JSEncrypt()
            encrypt.setPublicKey(data.publicKey)
            /* eslint-disable */
            encrypted = encrypt.encrypt(data.password)
            resolve(
              ajax(
                { ...data, password: encrypted, type: 1 },
                {
                  url: getConfig().serverApi + '/login/loginAjax',
                  method: 'post'
                }
              )
            )
          })
      },
      (reject) => {
        reject()
      }
    )
  },
  // 获取菜单
  postUserMenu (data) {
    return ajax({ channel: 1 }, {
      url: getConfig().serverApi + '/menu/queryMenuTreeList',
      method: 'post'
    })
  },
}
