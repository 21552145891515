// 本地化用户姓名（姓，名）
const localName = (vue, lastName, firstName) => {
  let last = ''
  if (lastName) last = lastName
  let first = ''
  if (firstName) first = firstName
  if (last !== '' || first !== '') {
    if (vue.config.nameSort) {
      return last + ' ' + first
    } else {
      return first + ' ' + last
    }
  } else {
    return ''
  }
}

export default localName
