import VueCookie from 'vue-cookie'
import LocalStorage from 'local-storage'

// 清除本地数据
const clearLocalData = () => {
  VueCookie.delete('userId')
  VueCookie.delete('userName')
  VueCookie.delete('saas-token')
  VueCookie.delete('phone')
  // VueCookie.delete('ability')
  LocalStorage.remove('ability')
  VueCookie.delete('role')
  VueCookie.delete('email')
  VueCookie.delete('activePath')
  // VueCookie.delete('rememberAccount')
  // VueCookie.delete('account')
  // VueCookie.delete('password')
  VueCookie.delete('isDemoAccount')
  VueCookie.delete('richeninfo-logo')
  VueCookie.delete('richeninfo-minilogo')
  VueCookie.delete('richeninfo-systemName')
  VueCookie.delete('showMenu', '')
  LocalStorage.remove('navData')
}

export default clearLocalData
