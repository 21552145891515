// 滚动
let sign = 80 // 触发值大小
const scrollY = (vue, ref, callback) => {
  if (vue.$local.get('scrollYSign') !== sign) vue.$local.set('scrollYSign', sign, 1000 * 60 * 60 * 24 * 31)
  const oScrollTop = vue.$refs[ref].scrollTop
  let isDown = false // 是否为向下滚动
  if (oScrollTop > sign) {
    sign = oScrollTop // 更新scrollTop
    isDown = true
  }
  if (oScrollTop < sign) {
    sign = oScrollTop // 更新scrollTop
    isDown = false
  }
  callback(oScrollTop, isDown)
}

export default {
  scrollY
}
