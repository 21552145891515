import Vue from 'vue'
import getConfig from './../config'

const codes = []

function extra (arr) {
  if (arr) {
    arr.forEach(ele => {
      codes.push(ele.name)
      if (ele.node && ele.node.length > 0) {
        extra(ele.node)
      }
    })
  }
}

const checkButton = (name = '', system = getConfig().name) => {
  if (codes.length === 0) {
    extra(Vue.local.get('navData'))
  }
  console.log('codes', codes)
  return codes.includes(name)
}

export default checkButton
