const global = {
  message: '-',
  submit: '提交',
  save: '保存',
  query: '查询',
  reset: '重置',
  add: '新增',
  remove: '删除',
  edit: '编辑',
  details: '详情',
  valid: '停用',
  enable: '启用',
  export: '导出',
  import: '导入',
  confirm: '确 定',
  cancel: '取 消',
  submitOk: '提交成功',
  handleOk: '操作成功',
  退出登录: '退出登录',
  请登录: '请登录',
  删除: '删除',
  编辑: '编辑',
  同步: '同步',
  removeTooltip: '删除后会导致相关数据显示异常，是否确认删除',
  enableTooltip: '请确认是否停用此数据',
  disableTooltip: '请确认是否启用此数据',
  emptyMsg: '不能全为空格',
  floatMsg: '请输入0或正数',
  backText: '返回',
  emailErrorMsg: '电子邮箱格式错误',
  phoneErr: '请输入正确手机号',
  fileErr: '文件上传失败,请稍后重试',
  imgSizeErr: '图片不能超过2M',
  IntMsg: '请输入正确的信息', // '请输入0或正整数',
  numSizeMsg: '数字过大',
  keywordMsg: '请输入关键字',
  searchText: '搜索',
  formatErr: '最少8位，包括至少1个大写字母，1个小写字母，1个数字',
  seartSearchMsg: '请输入查询内容',
  emptyResMsg: '暂无搜索结果',
  back: '返回上一级',
  backHome: '返回首页',
  请输入: '请输入',
  请选择: '请选择',
  请输入密码: '请输入密码',
  确认密码: '确认密码',
  请再次填写密码: '请再次填写密码',
  序号: '序号',
  备注: '备注',
  操作: '操作',
  在用: '在用',
  停用: '停用',
  上线: '上线',
  下线: '下线',
  推荐: '推荐',
  取消推荐: '取消推荐',
  增行: '增行',
  是: '是',
  否: '否',
  有: '有',
  无: '无',
  上传: '上传',
  年度: '年度',
  排放因子: '排放因子',
  减排物: '减排物',
  因子类型: '因子类型',
  客户名称: '客户名称'
}
const menu = {
  客户管理: '客户管理',
  平台管理: '平台管理',
  因子库: '因子库',
  数据字典: '数据字典',
  编码规则: '编码规则',
  废弃物库: '废弃物库',
  结账情况: '结账情况',
  推荐资产及项目: '推荐资产及项目',
  碳知乎: '碳知乎',
  在线学碳: '在线学碳',
  碳道: '碳道',
  碳资讯: '碳资讯',
  碳商: '碳商',
  市场价格: '市场价格',
  排放源: '排放源',
  行业模板: '行业模板',
  行业标签: '行业标签',
  区域标签: '区域标签',
  部门标签: '部门标签',
  Lite: 'Lite',
  模板标签: '模板标签',
  区域标签: '区域标签',
  行业标签: '行业标签',
  行业模板: '行业模板',
  排放源: '排放源',
  数据字典管理: '数据字典管理',
  碳智减: '碳智减',
}

const list = {
  序号: '序号',
  状态: '状态',
  部门: '部门',
  组织机构: '组织机构',
  负责人: '负责人',
  备注: '备注',
  操作: '操作',
  创建时间: '创建时间',
  类别: '类别',
  更新时间: '更新时间',
  角色: '角色',
  登录名: '登录名',
  用户名: '用户名',
  '登录名/用户名': '登录名/用户名',
  创建人: '创建人',
  操作人: '操作人',
  客户名称: '客户名称',
  客户类别: '客户类别',
  管理员: '管理员',
  到期时间: '到期时间'
}
const add = {
  部门: '部门',
  组织机构: '组织机构',
  负责人: '负责人',
  状态: '状态',
  所在地: '所在地',
  内容: '内容',
  备注: '备注',
  类别: '类别',
  角色: '角色',
  手机号: '手机号',
  密码: '密码',
  用户账号: '用户账号',
  用户名称: '用户名称',
  确认密码: '确认密码'
}
const Park = {
  add: {
    name: '客户名称',
    adminName: '管理员',
    expireDate: '到期时间',
    baseInfoText: '基本信息',
    nameMsg: '请输入客户名称',
    adminNameLabel: '管理员名称',
    adminNameLabelMsg: '请输入管理员名称',
    type: '合并方法',
    typeMsg: '请选择合并方法',
    loginName: '登录用户名(邮箱)',
    loginNamemsg: '请输入登录用户名(邮箱)',
    passWord: '登录密码',
    passWordMsg: '请输入登录密码',
    formatErr: '最少8位，包括至少1个大写字母，1个小写字母，1个数字',
    confirmPwd: '确认密码',
    confirmPwdMsg: '请再次输入登录密码',
    confirmPwdErr: '请再次输入登录密码',
    img: '图片',
    phone: '联系方式(手机号)',
    phoneMsg: '请输入联系方式(手机号)',
    setting: '配置信息',
    endTime: '授权到期时间',
    endTimeMsg: '请选择授权到期时间',
    authorizeCount: '授权用户量',
    authorizeCountMsg: '请填写授权用户量',
    operatingText: '操作权限',
    operatingTextMsg: '操作权限',
    typeOptOne: '运营控制权',
    typeOptTwo: '股权比例',
    tabOne: '权限中心'
  }
}

const Factor = {
  list: {
    '产品编号/名称': '产品编号/名称',
    排放物: '排放物',
    消耗物: '消耗物',
    因子类型: '因子类型',
    消耗物单位: '消耗物单位',
    关联排放源: '关联排放源',
    自测值状态: '自测值状态',
    计算因子: '计算因子',
    因子单位: '因子单位',
    修改时间: '修改时间',
    updateSelfTestStatus: '启用/停用自测值'
  },
  add: {
    新增自定义因子: '新增自定义因子',
    新增因子: '新增因子',
    编辑因子: '编辑因子',
    因子详情: '因子详情',
    排放物: '排放物',
    计算因子: '计算因子',
    数据来源: '数据来源',
    因子单位: '因子单位',
    消耗物: '消耗物',
    因子等级: '因子等级',
    消耗物单位: '消耗物单位',
    因子说明: '因子说明',
    状态: '状态',
    在用: '在用',
    停用: '停用',
    排放物Msg: '请选择排放物',
    计算因子Msg: '请输入计算因子',
    数据来源Msg: '请输入数据来源',
    因子单位Msg: '请输入因子单位',
    消耗物Msg: '请输入消耗物',
    因子等级Msg: '请输入因子等级',
    消耗物单位Msg: '请输入消耗物单位',
    因子说明Msg: '请输入因子说明',
    状态Msg: '请选择可用状态'
  }
}

const CustomFeign = {
  list: {
    减排方案推荐: '减排方案推荐',
    排放源: '排放源',
    消耗物: '消耗物',
    当前采用因子值: '当前采用因子值',
    因子单位: '因子单位',
    全网最低值: '全网最低值',
    最小值来源: '最小值来源',
    推荐减排方案: '推荐减排方案',
    方案: '方案',
    效果: '效果',
    说明: '说明'
  },
  add: {
    推荐资产及项目: '推荐资产及项目',
    技术减排方案推荐: '技术减排方案推荐'
  }
}

const MarketPrice = {
  list: {
    市场名称: '市场名称',
    区域: '区域',
    产品: '产品',
    日期: '日期',
    币种: '币种',
    开盘价: '开盘价',
    收盘价: '收盘价',
    最低价: '最低价',
    最高价: '最高价',
    均价: '均价',
    成交量: '成交量'
  },
  add: {
    新增市场价格: '新增市场价格',
    编辑市场价格: '编辑市场价格',
    市场价格详情: '市场价格详情',
    市场名称: '市场名称',
    区域: '区域',
    产品: '产品',
    币种: '币种',
    币种Msg: '请选择产品',
    开盘价: '开盘价',
    收盘价: '收盘价',
    最低价: '最低价',
    最高价: '最高价',
    均价: '均价',
    成交量: '成交量',
    日期: '日期',
    市场名称Msg: '请选择市场名称',
    区域Msg: '请选择区域',
    产品Msg: '请选择产品',
    开盘价Msg: '请输入开盘价或0',
    收盘价Msg: '请输入收盘价或0',
    最低价Msg: '请输入最低价或0',
    最高价Msg: '请输入最高价或0',
    均价Msg: '请输入均价或0',
    成交量Msg: '请输入成交量或0',
    日期Msg: '请选择日期范围',
    开始日期Msg: '开始日期',
    结束日期Msg: '结束日期'
  }
}

const Zhihu = {
  add: {
    新增视频: '新增视频',
    编辑视频: '编辑视频',
    查看视频: '查看视频',
    排序: '排序',
    视频标题: '视频标题',
    有无视频: '有无视频',
    标签: '标签',
    简介: '简介',
    视频ID: '视频ID',
    视频封面: '视频封面',
    视频文件: '视频文件',
    点击上传: '点击上传',
    请上传视频: '请上传视频',
    请上传封面: '请上传封面',
    fileNumBig: '视频数量超出限制',
    imgType: '请上传jpg png jpeg格式的图片',
    imgSize: '上传图片大小不能超过 2MB!',
    新增资讯: '新增资讯',
    编辑资讯: '编辑资讯',
    标题: '标题',
    类别: '类别',
    文章内容: '文章内容'
  },
  list: {
    视频标题: '视频标题',
    有无视频: '有无视频',
    排序: '排序',
    标签: '标签',
    视频ID: '视频ID',
    视频封面: '视频封面',
    视频文件: '视频文件',
    简介: '简介',
    标题: '标题',
    类别: '类别'
  }
}

const Consult = {
  addTitle: '新增资讯',
  editTitle: '编辑资讯',
  title: '标题',
  titleMsg: '请填写标题',
  categoryId: '类别',
  categoryIdMsg: '请选择类别',
  status: '状态',
  optOne: '上线',
  optTwo: '下线',
  updateTime: '最后操作时间',
  createUserName: '操作人',
  content: '文章内容',
  contentMsg: '请输入文章内容'
}

const Login = {
  userMsg: '请输入账号',
  pwdMsg: '请输入密码',
  phoneMsg: '请输入手机号'
}
const Dept = {
  name: '名称',
  code: '编码',
  nameMsg: '请输入名称',
  codeMsg: '请输入编码',
  addTitText: '新增部门标签',
  editTitText: '编辑部门标签',
  viewTitText: '查看部门标签'
}
const Area = {
  addTitText: '新增区域标签',
  editTitText: '编辑区域标签',
  viewTitText: '查看区域标签',
  carbonValue: '碳价值/KG CO2E',
  carbonValueMsg: '请输入碳价值/KG CO2E'
}
const industry = {
  行业ID: '行业ID',
  行业名称: '行业名称',
  行业名称Msg: '请输入行业名称',
  行业IDMsg: '请输入行业ID'
}
const obj = {
  global,
  menu,
  add, // 新增编辑详情
  list, // 列表
  Consult,
  Login,
  Dept,
  Area,
  industry
}

// 遇到相同元素级属性，以后者（main）为准
// 不返还新Object，而是main改变
function mergeJSON (minor, main) {
  for (var key in minor) {
    if (main[key] === undefined) { // 不冲突的，直接赋值
      main[key] = minor[key]
      continue
    }

    // 冲突了，如果是Object，看看有么有不冲突的属性
    // 不是Object 则以main为主，忽略即可。故不需要else
    if (isJSON(minor[key])) {
      // arguments.callee 递归调用，并且与函数名解耦
      arguments.callee(minor[key], main[key])
    }
  }
}

// 附上工具
function isJSON (target) {
  return typeof target === 'object' && target.constructor == Object
}

mergeJSON(Park, obj)
mergeJSON(Factor, obj)
mergeJSON(MarketPrice, obj)
mergeJSON(CustomFeign, obj)
mergeJSON(Zhihu, obj)

// mergeJSON(Emission, obj)
// mergeJSON(ProductMerge, obj)
// mergeJSON(Org, obj)
// mergeJSON(Role, obj)
// mergeJSON(User, obj)

console.log('obj', obj)
module.exports = obj
