const loadScript = (url) => {
  const script = document.createElement('script')
  let done = false
  const head = document.getElementsByTagName('head')[0]
  script.src = url
  head.appendChild(script)
  return new Promise(
    (resolve) => {
      script.onload = script.onreadystatechange = function () {
        if (
          !done &&
          (!this.readyState ||
            this.readyState === 'loaded' ||
            this.readyState === 'complete')
        ) {
          done = true
          resolve()
          // IE memory leak
          script.onload = script.onreadystatechange = null
          head.removeChild(script)
        }
      }
    },
    (reject) => {
      script.onerror = function () {
        const msg = url + ' loadError 请检查资源路径'
        reject(msg)
      }
    }
  )
}
export default loadScript
