import checkButton from './../tool/check-button'
import api from '@/api'
export default {
  filters: {
  },
  directives: {
    clickOutside: { // 是否点在元素外
      bind (el, binding, vnode) {
        vnode.context.globalClick(e => {
          if (!el.contains(e.target)) binding.value()
        })
      },
      role: {
        bind (el, binding, vnode) {
          if (!checkButton(binding.value)) {
            // el.style.opacity="0"
            el.style.display = 'none'
          }
        }
      }
    }
  },
  methods: {

    initPaseImgUpload () {
      window.addEventListener(
        "paste",
        (evt) => {
          if (
            evt.clipboardData &&
            evt.clipboardData.files &&
            evt.clipboardData.files.length
          ) {
            evt.preventDefault();
            [].forEach.call(evt.clipboardData.files, (file) => {
              if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                return;
              }
              const formData = new FormData();
              formData.append("file", file);
              console.log(formData, file)
              api.systemBase.uploadFile(formData).then((response) => {
                var range = this.$refs.myQuill.quill.selection.savedRange;
                //图片插入在富文本中的位置
                var index = 0;
                if (range == null) {
                  index = 0;
                } else {
                  index = range.index;
                }
                //将图片链接插入到当前的富文本当中
                this.$refs.myQuill.quill.insertEmbed(index, "image", response.data.url);
                // 调整光标到最后
                this.$refs.myQuill.quill.setSelection(index + 1); //光标后移一位
              })
            })
          }
        })
    },
    initPaseImgUploadLang () {
      window.addEventListener(
        "paste",
        (evt) => {
          if (
            evt.clipboardData &&
            evt.clipboardData.files &&
            evt.clipboardData.files.length
          ) {
            evt.preventDefault();
            [].forEach.call(evt.clipboardData.files, (file) => {
              if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                return;
              }
              const formData = new FormData();
              formData.append("file", file);
              console.log(formData, file)
              api.systemBase.uploadFile(formData).then((response) => {
                var range = this.$refs.myQuillLang.quill.selection.savedRange;
                //图片插入在富文本中的位置
                var index = 0;
                if (range == null) {
                  index = 0;
                } else {
                  index = range.index;
                }
                //将图片链接插入到当前的富文本当中
                this.$refs.myQuill.myQuillLang.insertEmbed(index, "image", response.data.url);
                // 调整光标到最后
                this.$refs.myQuill.myQuillLang.setSelection(index + 1); //光标后移一位
              })
            })
          }
        })
    },
    handleAvatarSuccess (res, file) {
      if (res.code === '500') return this.$message.error('上传失败')
      this.$set(this.deviceInfoForm, 'bannerImgUrl', this.config.ftpUrl + '/' + res.data.newFileName)
    },
    handleAvatarSuccesslist (res, file) {
      if (res.code === '500') return this.$message.error('上传失败')
      this.$set(this.deviceInfoForm, 'listImgUrl', this.config.ftpUrl + '/' + res.data.newFileName)
    },
    handleAvatarSuccessLang (res, file) {
      if (res.code === '500') return this.$message.error('上传失败')
      this.$set(this.deviceInfoFormLang, 'bannerImgUrl', this.config.ftpUrl + '/' + res.data.newFileName)
    },
    handleAvatarSuccesslistLang (res, file) {
      if (res.code === '500') return this.$message.error('上传失败')
      this.$set(this.deviceInfoFormLang, 'listImgUrl', this.config.ftpUrl + '/' + res.data.newFileName)
    },
    async beforeAvatarUpload (file) {
      const isImg = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'

      if (!isImg) {
        return this.$message.error(this.$t('add.imgType'));
      }
      if (isImg) {
        this.loadingImg = true
      }
      return isImg
    },
  },
  mounted () {
  }
}
