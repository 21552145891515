import ajax from '@/api/ajax'
import getConfig from '@/config'

export default {
  save (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/businessCase/saveBusinessCase',
      method: 'post',
    })
  },

  remove (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/businessCase/deleteBusinessById',
      method: 'post',
    })
  },

  update (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/businessCase/updateBusinessCase',
      method: 'post',
    })
  },
  findById (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/businessCase/findBusinessCaseById',
      method: 'post',
    })
  },
  queryPage (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/businessCase/queryBusinessCasePage',
      method: 'post',
    })
  },
  updateStatusById (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/businessCase/updateBusinessCaseStatusById',
      method: 'post',
    })
  }
}
