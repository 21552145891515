import systemBase from './systemBase'
import banner from './banner'
import custom from './custom'
import partner from './partner'
import consult from './consult'
import industryCase from './industryCase'
import joinUs from './joinUs'
import contactUs from './contactUs'
import contactUsLite from './contactUsLite'
const modules = {
  systemBase,
  banner,
  custom,
  partner,
  consult,
  industryCase,
  joinUs,
  contactUs,
  contactUsLite
}


export default modules
