const getRoleId = role => {
  console.log(role)
  const options = [{
    label: 'BANKUSER',
    value: 1
  }, {
    label: 'TENANTUSER',
    value: 2
  }, {
    label: 'USER',
    value: 2
  }, {
    label: 'SUPERUSER',
    value: 1
  }]
  return options.find(item => item.label === role).value
}

export default getRoleId
