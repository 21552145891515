import LocalStorage from 'local-storage'

// import bank from './bank.json'
// import tenant from './tenant.json'
// import user from './user.json'
/*
* type 字段暂时弃用
* */
export default (type = '') => {
  // if (type === 'BANKUSER') return bank
  // if (type === 'TENANTUSER') return tenant
  // return user
  // if (type === 'BANKUSER') return tenant
  // return bank
  return (LocalStorage.get('navData') || {}).value
}

// const currentMenu = {}
const deepfindCurrentMenu = (data) => {
  data.forEach(item => {
    if (item.node.length > 0) {
      findCurrentMenu(item.node)
    } else if (item.path === '/overview/overview-list') {
      return item
    }
  })
}
export const findCurrentMenu = () => {
  const menuData = (LocalStorage.get('navData') || {}).value || []
  return deepfindCurrentMenu(menuData)
}
export function getPathNavData (path) {
  var a = (LocalStorage.get('navData') || {}).value
  return a
  // var x = 0
  // a.forEach((ele, index) => {
  //   if (path.indexOf(ele.path) > -1) {
  //     x = index
  //   }
  // })
  // return a[x].node
}
