
/**
 * 表格矩阵初级预算
 * 目前只支持行合并
 * 获取排序后的数据->排序->演算出合并单元格的矩阵数据->返回矩阵数据 适用于elementUi table :span-method方法
 *   请先排序数据  可以使用自带的tableMatrix.sort
 *   参考代码 return this.tableMatrix[columnIndex][rowIndex]
 *   其他的ui也可以参考
 * @param matrixIndex   { key1:1,key2:2,key3:5
 * @param list  排序后的数据
 * @return matrix  矩阵数据
 */

function matrix (matrixIndex, list) {
  var matrixJSON = { // 数组是有序 对象是无序
  }
  var matrix = {} // 最终返回

  list.forEach((ele) => {
    for (var key in matrixIndex) {
      if (!matrixJSON[key]) {
        matrixJSON[key] = [({ [key]: ele[key], value: [ele] })]
      } else {
        var index = matrixJSON[key].findIndex(ele2 => ele2[key] == ele[key])
        if (index == -1) {
          matrixJSON[key].push({ [key]: ele[key], value: [ele] })
        } else {
          matrixJSON[key][index].value.push(ele)
        }
      }
    }
  })

  // console.log('matrixJSON', matrixJSON)
  for (var i in matrixJSON) {
    var items = []

    for (var k in matrixJSON[i]) {
      var rowspan = makeSpan(matrixJSON[i][k].value.length)

      items = [...items, ...rowspan]
    }
    matrix[matrixIndex[i]] = items
  }

  // console.log('matrix', matrix)
  return matrix
}

export function makeSpan (rowSpan) {
  var spans = []
  spans.push({
    rowspan: rowSpan,
    colspan: 1
  })
  for (var i = 0; i < (rowSpan - 1); i++) {
    spans.push({
      rowspan: 0,
      colspan: 0
    })
  }
  return spans
}

function sort (list, key) {
  // 排序
  function compare (a, b) {
    // 使用 toUpperCase() 忽略字符大小写
    const bandA = a[key].toUpperCase()
    const bandB = b[key].toUpperCase()

    let comparison = 0
    if (bandA > bandB) {
      comparison = 1
    } else if (bandA < bandB) {
      comparison = -1
    }
    return comparison
  }
  return list.sort(compare)
}
export default {
  matrix: matrix,
  sort: sort
}
