<template>
  <div class="g-box g-login-box">
    <span>获取菜单中...</span>
  </div>
</template>

<script>
import Vue from 'vue'
import api from './../../api'
import VueCookie from 'vue-cookie'
import LocalStorage from 'local-storage'

// 清除本地数据
const clearLocalData = () => {
  VueCookie.delete('userId')
  VueCookie.delete('userName')
  VueCookie.delete('saas-token')
  VueCookie.delete('phone')
  // VueCookie.delete('ability')
  LocalStorage.remove('ability')
  VueCookie.delete('role')
  VueCookie.delete('email')
  VueCookie.delete('activePath')
  // VueCookie.delete('rememberAccount')
  // VueCookie.delete('account')
  // VueCookie.delete('password')
  VueCookie.delete('isDemoAccount')
  VueCookie.delete('richeninfo-logo')
  VueCookie.delete('richeninfo-minilogo')
  VueCookie.delete('richeninfo-systemName')
  VueCookie.delete('showMenu', '')
  LocalStorage.remove('navData')
}

export default Vue.extend({
  name: 'desk',
  components: {},
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      formInline: {
        page: {
          total: 0,
          pageSize: 10,
          currentPage: 1
        }
      },
      tabActive: 'account',
      ruleForm: {
        account: '',
        accountTips: '',
        password: '',
        passwordTips: '',
        rememberAccount: false,
        phone: '',
        phoneTips: '',
        verificationCode: '',
        verificationCodeState: false,
        countdown: 60,
        haloImg: '',
        logo: '',
        systemName: ''
      },
      filter: {
      },
      interval: {},
      homePath: ''
    }
  },
  computed: {

  },
  watch: {},
  beforeCreate () {
  },
  created () {
    if (this.$store.state.user.userRole != 'BANKUSER') {
      this.$alert('账号或密码错误', '提示', {
        type: 'warning'
      }).then(() => {
        clearLocalData()
        this.$router.replace('/login')
      })
      return
    }
    this.init()
  },
  beforeMount () {
  },
  mounted () {
    // loadScene()
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    clearInterval(this.interval)
    this.deleteGlobalResize('login')
  },
  destroyed () {
  },
  methods: {
    init () {
      api.systemBase.postUserMenu().then(res => {
        Vue.local.set('navData', res.data || [], 1000 * 60 * 60 * 24 * 31)
        if (res.data.length === 0) {
          this.$alert('该账号没有菜单 请先联系管理员配置', {
            confirmButtonText: '确定',
            callback: action => {
              VueCookie.delete('userId')
              // VueCookie.set('email', res.data.user.email || '')
              VueCookie.delete('saas-token')
              VueCookie.delete('orgId') // 组织id
              VueCookie.delete('parkId') // 园区id
              // VueCookie.set('pageUrl', res.data.pageUrl || '')
              VueCookie.delete('role')

              this.$store.commit('clearUser', {})
              this.$router.replace('/login')
            }
          })
        } else {
          this.$router.replace(res.data[0].node[0].path)
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped>
@import "./../../assets/css/global-variable";
.g-login-box {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>
