import Vue from 'vue'
import ElementUI from 'element-ui'
import Storage from 'vue-ls'
import Axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import mixin from './mixin'
import moment from 'moment'
import tool from './tool'
import getConfig from './config/index'
import Echarts from 'echarts'
import VCharts from 'v-charts'
import mNav from './components/rui-m-nav'
import mHeader from './components/rui-m-header'
import VueI18n from 'vue-i18n'
import i18n from './i18n/i18n'
import ImportPop from './components/importPop'
Vue.use(ImportPop)
Vue.use(VueI18n)

Vue.config.productionTip = false
Vue.use(Storage, {
  namespace: '',
  name: 'local',
  storage: 'local'
})
Vue.use(ElementUI)
Vue.use(mNav)
Vue.use(mHeader)

window.axiosPromiseArr = []
Axios.interceptors.request.use(config => { // 请求拦截器
  config.cancelToken = new Axios.CancelToken(cancel => {
    window.axiosPromiseArr.push({
      cancel,
      url: config.url
    })
  })
  return config
}, error => {
  return Promise.reject(error)
})

Object.defineProperty(Vue.prototype, '$moment', { value: moment })
Object.defineProperty(Vue.prototype, 'tool', { value: tool })
Object.defineProperty(Vue.prototype, 'config', { value: getConfig() })

/* eslint-disable */
Vue.local.set('surroundings', process.env, 1000 * 60 * 60 * 24 * 31) // 记录当前环境信息
/* eslint-disable */

Vue.mixin(mixin)

Vue.use(VCharts)

Vue.prototype.bus = new Vue()
Vue.prototype.echarts = Echarts

let globalResizeArr = []
Vue.prototype.globalResize = (callback, id = null) => { // 全局窗口大小变化事件
  globalResizeArr.push({
    id,
    callback
  })
  window.onresize = e => {
    if (!globalResizeArr.length) return
    globalResizeArr.forEach(data => {
      data.callback(e)
    })
  }
}
Vue.prototype.deleteGlobalResize = id => {
  globalResizeArr = globalResizeArr.filter(item => item.id !== id)
}

const globalLoadArr = []
Vue.prototype.globalLoad = callback => { // 全局窗口加载事件
  globalLoadArr.push(callback)
  window.onload = () => {
    globalLoadArr.forEach(data => {
      data()
    })
  }
}



let vm = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
