// 根据手机号查询国家区号信息
import phoneCodeCountry from './phone-code-countries'

export default phoneNumber => {
  const code = phoneNumber.substring(phoneNumber.indexOf('+') + 1, phoneNumber.indexOf(' '))
  const phone = phoneNumber.substring(phoneNumber.indexOf(' ') + 1)
  let country = {
    country: '',
    code: '',
    areaCode: '',
    phone: phone
  }
  phoneCodeCountry.forEach(data => {
    if (code === data[2]) {
      country = {
        country: data[0],
        code: data[1],
        areaCode: data[2],
        phone: phone
      }
    }
  })
  return country
}
