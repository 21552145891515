import localName from './local-name'
import getBrowserInfo from './get-browser-info'
import scroll from './scroll'
import commaIt from './comma-it'
import encodeUtf8 from './encode-utf8'
import decodeUtf8 from './decode-utf8'
import phoneNumberToCountry from './phone-number-to-country/index'
import notify from './notify'
import trim from './trim'
import getRequest from './get-request'
import checkLogin from './check-login'
import clearLocalData from './clear-local-data'
import recursionFind from './recursion-find'
import recursionFindProp from './recursion-find-prop'
import checkButton from './check-button'
import getRoleId from './get-role-id'
import loadScript from './loadScript'
import tableMatrix from './tableMatrix'
import AES from './AES'

export function treearr (arr, remove) {
  var arr2 = []

  for (let i = arr.length - 1; i >= 0; i--) {
    if (remove && arr[i].disabled) {
      arr.splice(i, 1)
    } else if (arr[i].node && arr[i].node.length > 0) {
      treearr(arr[i].node, remove)
    } else {
      delete arr[i].node
    }
  }

  arr2 = arr
  return arr2
}
export const deepFn = (data) => {
  const arr = data.map(item => {
    return {
      ...item,
      node: item.node.length > 1 ? deepFn(item.node) : null
    }
  })
  return arr
}

export default {
  phoneNumberToCountry, // 根据手机号查询国家区号信息
  localName, // 本地化用户姓名（姓，名）
  getBrowserInfo, // 获取当前浏览器信息
  scrollY: scroll.scrollY, // 纵向滚动
  commaIt, // 金额格式化
  encodeUtf8, // 字符串转编码
  decodeUtf8, // 将以UTF-8编码的字节序列解码为String
  notify, // 系统级消息推送
  trim,
  getRequest, // 获取url中的query的参数
  checkLogin, // 检查登录状态完整性
  recursionFind, // 递归查找
  recursionFindProp,
  checkButton, // 检查按钮权限
  getRoleId, // 获取角色id
  clearLocalData, // 清除本地数据
  loadScript,
  treearr,
  AES,
  tableMatrix // table矩阵数据

}
