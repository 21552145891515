import configDev from './dev'
import configProd from './prod'
import configTest from './test'
/* eslint-disable */
const getConfig = (env = process.env.VUE_APP_SECRET) => {
  /* eslint-disable */
  let config = {}
  switch (env) {
    case 'prod':
      config = configProd
      break
    case 'test':
      config = configTest
      break
    default:
      config = configDev
  }
  return config
}
export default getConfig
