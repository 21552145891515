import routerUtils from './../router-utils'
// import VueCookie from 'vue-cookie'

const mainBox = () => import('../../layout/main-layout').catch(() => routerUtils())
const errorSub = () => import('./../../layout/errorSub.vue').catch(() => routerUtils())
const bank = [
  {
    path: '/',
    redirect: '/desk'
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: errorSub,
    meta: {
      forbidden: true,
      auth: false
    }
  },
  {
    path: '',
    component: mainBox,
    children: [
      {
        path: '/bannerMerge-list',
        name: 'bannerMerge-list',
        component: () => import('@/views/homeMerge/bannerMerge/list.vue').catch(() => routerUtils())
      },
      {
        path: '/customMerge-list',
        name: 'customMerge-list',
        component: () => import('@/views/homeMerge/customMerge/list.vue').catch(() => routerUtils())
      },
      {
        path: '/partnerMerge-list',
        name: 'partnerMerge-list',
        component: () => import('@/views/homeMerge/partnerMerge/list.vue').catch(() => routerUtils())
      },
      {
        path: '/companyDynamic-list',
        name: 'companyDynamic-list',
        component: () => import('@/views/homeMerge/consultMerge/list.vue').catch(() => routerUtils())
      },
      {
        path: '/companyDynamic-add',
        name: 'companyDynamic-list',
        component: () => import('@/views/homeMerge/consultMerge/add.vue').catch(() => routerUtils())
      },
      {
        path: '/companyDynamic-add/:id/:associatedId',
        name: 'companyDynamic-list',
        component: () => import('@/views/homeMerge/consultMerge/add.vue').catch(() => routerUtils())
      },
      {
        path: '/industry-case-list',
        name: 'industry-case-list',
        component: () => import('@/views/industry-case/list.vue').catch(() => routerUtils())
      },
      {
        path: '/industry-case-add',
        name: 'industry-case-list',
        component: () => import('@/views/industry-case/add.vue').catch(() => routerUtils())
      },
      {
        path: '/industry-case-add/:id/:associatedId',
        name: 'industry-case-list',
        component: () => import('@/views/industry-case/add.vue').catch(() => routerUtils())
      },
      {
        path: '/join-us-list',
        name: 'join-us-list',
        component: () => import('@/views/join-us/list.vue').catch(() => routerUtils())
      },
      {
        path: '/join-us-add',
        name: 'join-us-list',
        component: () => import('@/views/join-us/add.vue').catch(() => routerUtils())
      },
      {
        path: '/join-us-add/:id/:associatedId',
        name: 'join-us-list',
        component: () => import('@/views/join-us/add.vue').catch(() => routerUtils())
      },
      {
        path: '/contact-us-list',
        name: 'contact-us-list',
        component: () => import('@/views/contact-us/list.vue').catch(() => routerUtils())
      },
      {
        path: '/contact-us-lite-list',
        name: 'contact-us-lite-list',
        component: () => import('@/views/contact-lite-us/list.vue').catch(() => routerUtils())
      },
      {
        path: '*',
        name: 'errorSub',
        component: errorSub,
        meta: {
          auth: false
        }
      }
    ]
  }
]

export default bank
