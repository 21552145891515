const commaIt = (num, options, isShowSeparator = true) => {
  const decimalSeparator = (options && options.decimalSeparator) || '.'
  let number = num.toString()
  number = Number(number).toFixed(2).toString()
  if (number.length === 0) return '0' + decimalSeparator + '00'

  let negativeSign = ''
  if (number.indexOf('-') === 0) {
    number = number.replace('-', '')
    negativeSign = '-'
  }

  const precision = (options && typeof options.precision !== 'undefined') ? parseInt(options.precision, 10) : 2
  let thousandSeparator = (options && options.thousandSeparator) || ' '
  if (!isShowSeparator) thousandSeparator = ''
  const replaceRegex = '$1' + thousandSeparator

  const amount = number.split(decimalSeparator)[0]
  const floats = (precision > 0) ? (decimalSeparator + ((number.split(decimalSeparator)[1] || '') + '00').substr(0, precision)) : ''
  const numberIfIed = amount.split('').reverse().join('').replace(/(\d{3}(?!$))/g, replaceRegex).split('').reverse().join('')

  if (isNaN(Number(num))) {
    return num
  } else {
    return negativeSign + numberIfIed + floats
  }
}

export default commaIt
