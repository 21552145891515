const baseUrl = 'https://dev.carbonebook.com/tantan-portal'

const config = {
  port: 8011,
  name: 'ZEUS',
  room: '', // 静态服务地址
  pathZeus: 'https://dev.carbonebook.com/tantan-door-admin', // 权限中心(未登录重定向到综管平台地址)
  serverApi: baseUrl + '/portal-api', // demo版对应地址
  uploadUrl: baseUrl + '/portal-api/upload/uploadFile',
  ftpUrl: 'https://media.carbonebook.com',
  secretKey: 'richeninfo'
}
export default config
