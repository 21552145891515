<template>
  <div class="g-box g-m-header-box">
    <div class="u-tt">
      <div class="u-h4">
        <span
          class="u-h4-icon iconfont el-icon-s-fold"
          v-show="expand"
          @click="funExpandChange"
        ></span>
        <span
          class="u-h4-icon iconfont el-icon-s-unfold"
          v-show="!expand"
          @click="funExpandChange"
        ></span>
        <span class="u-h4-text" v-if="$route.meta.title">{{
          $t(`menu['${$route.meta.title}']`)
        }}</span>
      </div>
    </div>
    <div class="header-menu">
      <ul class="header-menu-ul">
        <!--            <li class="header-menu-item" :class="{'cur':$route.path.indexOf(item.path)>-1}" v-for="(item,index) in topMenu" :key="index" @click="funNavClick(item)">{{ item.title }}</li>-->
      </ul>
    </div>
    <div class="header-right">
      <!--      <span class="right-item lang">-->
      <!--                <el-dropdown>-->
      <!--                  <span class="el-dropdown-link">-->
      <!--                    <span class="lang-item" v-if="$i18n.locale == 'zh'"><span class="lang-icon"><img :src="publicPath+'/lang/zh.png'" /></span> 中文 <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i></span>-->
      <!--                   <span class="lang-item" v-if="$i18n.locale == 'en'"><span class="lang-icon"><img :src="publicPath+'/lang/en.png'" /></span>  English  <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i></span>-->
      <!--                  </span>-->
      <!--                  <el-dropdown-menu slot="dropdown">-->
      <!--                    <el-dropdown-item-->
      <!--                            @click.native="toggleLang('zh')"-->
      <!--                            :disabled="$i18n.locale == 'zh'"-->
      <!--                    ><span class="lang-icon"><img :src="publicPath+'/lang/zh.png'" /></span>  中文</el-dropdown-item>-->
      <!--                    <el-dropdown-item-->
      <!--                            @click.native="toggleLang('en')"-->
      <!--                            :disabled="$i18n.locale == 'en'"-->
      <!--                    ><span class="lang-icon"><img :src="publicPath+'/lang/en.png'" /></span>  English</el-dropdown-item>-->
      <!--                  </el-dropdown-menu>-->
      <!--                </el-dropdown>-->
      <!--              </span>-->
      <el-dropdown v-if="$store.state.user.userName">
        <span class="el-dropdown-link">
          <span style="display: flex; align-items: center">
            <span class="avator"><img :src="avator" alt="头像" /></span>
            <span>{{ $store.state.user.userName }}</span>
          </span>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="handleLogout()">{{
            $t("global.退出登录")
          }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span class="right-item" v-else @click="handleLogout">{{
        $t("global.请登录")
      }}</span>
    </div>
  </div>
</template>

<script>
import VueCookie from 'vue-cookie'
import getNavData from './nav-data/index'
import clearLocalData from './clear-local-data'
import getConfig from '../../config'
import tool from '../../tool'
import api from '../../api/index.js'

export default {
  name: 'm-header',
  components: {},
  directives: {},
  filters: {},
  props: {
    apiPostMenuBuildMenu: {
      type: Function,
      default: () => { }
    },
    apiPostParkParkOut: {
      type: Function,
      default: () => { }
    },
    path: {
      type: String,
      default: 'http://'
    },
    localStorage: {
      type: Object,
      default: () => { }
    },
    systemName: {
      type: String,
      default: '智慧园区综合管理平台'
    }
  },
  data () {
    return {
      title: [],
      full: false,
      expand: true,
      publicPath: getConfig().pathZeus,
      userName: VueCookie.get('userName'),
      imgUrl: VueCookie.get('pageUrl'),
      isDemoAccount: VueCookie.get('isDemoAccount'),
      filter: {
        userRoleOptions: [{
          label: '普通用户',
          value: 'USER'
        }, {
          label: '租户管理员',
          value: 'TENANTUSER'
        }, {
          label: '超级管理员',
          value: 'BANKUSER'
        }, {
          label: '超管用户',
          value: 'SUPERUSER'
        }]
      },
      topMenu: [],
      role: VueCookie.get('role'),
      avator: getConfig().pathZeus + '/avator.png'
    }
  },
  computed: {
    roleName () {
      const map = {}
      this.filter.userRoleOptions.forEach(item => {
        map[item.value] = item.label
      })
      return this.isDemoAccount ? map.BANKUSER : map[this.role] || ''
    }
  },
  watch: {
    $route () {
      this.title = []
      this.init(getNavData(VueCookie.get('role')))
    }
  },
  created () {
    this.title = []
    this.$emit('expandChange', this.expand)
    this.init(getNavData(VueCookie.get('role')))
    this.initTopMenu(getNavData(VueCookie.get('role')))
  },
  mounted () {
    document.addEventListener('fullscreenchange', event => {
      if (document.fullscreenElement) {
        this.full = true
      } else {
        this.full = false
      }
    })
  },
  methods: {
    pushMsg () {
      this.$nextTick(() => {
        window.location.href = getConfig().pathSelene + '/smartCommunity/msg-center-list'
      })
    },
    funExpandChange () {
      this.expand = !this.expand
      this.$emit('expandChange', this.expand)
      if (document.querySelector('.m-page')) {
        if (this.expand) {
          document.querySelector('.m-page').style.transition = 'all ease .3s'
          document.querySelector('.m-page').style.width = 'calc(100% - 270px)'
        } else {
          document.querySelector('.m-page').style.transition = 'all ease .3s'
          document.querySelector('.m-page').style.width = 'calc(100% - 64px)'
        }
      }
    },
    toggleLang (lang) {
      if (lang === 'zh') {
        localStorage.setItem('locale', 'zh')
        this.$i18n.locale = localStorage.getItem('locale')
        // this.$message({
        //   message: '切换为中文！',
        //   type: 'success'
        // })
      } else if (lang === 'en') {
        localStorage.setItem('locale', 'en')
        this.$i18n.locale = localStorage.getItem('locale')
        // this.$message({
        //   message: 'Switch to English!',
        //   type: 'success'
        // })
      }
    },
    handleLogout () {
      tool.clearLocalData()
      this.$store.commit('clearUser', {})
      this.$router.replace('/login')
    },
    funFull () {
      if (!document.fullscreenElement) {
        document.body.requestFullscreen()
        this.full = true
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
          this.full = false
        }
      }
    },
    funNavClick (item) {
      if (item.name === 'home') {
        this.$router.push('/home')
        return
      }
      if (item.node.filter(ele => ele.type === 'nav').length === 0) {
        if (item.path !== '') {
          this.$router.push(item.path)
        } else {
          this.$message.error('抱歉 你没有该模块菜单 请联系管理员')
        }
        return
      }
      var path = ''
      // 目前只支持3级
      if (item.node[0].node.filter(ele => ele.type === 'nav').length > 0) {
        path = item.node[0].node[0].path
        this.$router.push(path)
      } else {
        path = item.node[0].path
        this.$router.push(path)
      }
    },
    initTopMenu (list) {
      this.topMenu = list.map(item => item)
    },
    init (list) {
      list.forEach(item => {
        if (this.$route.path.indexOf(item.path) === 0) {
          this.title.push(item)
          return this.init(item.node)
        }
        return false
      })
    },
    signOut () {
      clearLocalData()
      this.$store.commit('clearUser', {})
      location.href = getConfig().pathZeus
    },
    parkOut () {
      this.apiPostParkParkOut().then(res => {
        this.afterLogin(res, () => {
          location.href = this.path + '/overview/overview-list'
        })
      })
    },
    afterLogin (res, callback = () => { }) {
      VueCookie.set('userId', res.data.id || '')
      VueCookie.set('email', res.data.email || '')
      VueCookie.set('userName', res.data.name || '')
      VueCookie.set('saas-token', res.data.accessToken || '')
      VueCookie.set('phone', res.data.phone || '')
      VueCookie.set('orgId', res.data.orgId || '') // 组织id
      VueCookie.set('orgName', res.data.orgName || '') // 组织名称
      VueCookie.set('parkId', res.data.parkId || '') // 园区id
      // VueCookie.set('ability', res.data.sysenum || '')
      this.localStorage.set('ability', (res.data.sysenum || []).join(','), 1000 * 60 * 60 * 24 * 31)
      VueCookie.set('pageUrl', res.data.pageUrl || '')
      VueCookie.set('scope', (res.data.scope || []).join(','))
      VueCookie.set('role', res.data.userRole || '')
      VueCookie.delete('showMenu', '')

      VueCookie.delete('isDemoAccount')
      this.getNavData(callback)
    },
    getNavData (callback = () => { }) {
      this.apiPostMenuBuildMenu({
        roleId: this.tool.getRoleId(VueCookie.get('role'))
      }).then(res => {
        this.localStorage.set('navData', res.data || [], 1000 * 60 * 60 * 24 * 31)
        callback()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./global-variable.scss";
.g-m-header-box {
  display: flex;
  height: 75px;
  justify-content: space-between;
  background-color: #fff;
  .header-menu {
    flex: 1;
    display: flex;
    align-items: center;
    overflow-y: hidden;

    &-ul {
      display: block;
      white-space: nowrap;
    }
    &-item {
      margin-left: 20px;
      margin-right: 40px;
      display: inline-block;
      font-size: 16px;
      cursor: pointer;
      position: relative;
      &.cur {
        color: #0677ff;
        &:after {
          content: "";
          position: absolute;
          bottom: -30px;
          left: 50%;
          margin-left: -40px;
          width: 80px;
          height: 0;
          border-bottom: solid 2px #0677ff;
        }
      }
    }
  }
  .u-tt {
    display: flex;
    .u-h4 {
      position: relative;
      padding-left: 20px;
      font-size: $fs-main-title;
      line-height: 75px;
      .u-h4-icon {
        font-size: $fs-main-title;
        cursor: pointer;
      }
      .u-h4-text {
        font-size: 16px;
        margin-left: 10px;
        position: relative;
        top: 0px;
        color: #666666;
      }
    }
  }
  .header-right {
    float: right;
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    padding-right: 40px;

    span.right-item {
      font-size: 14px;
      color: #666666;
      margin-left: 30px;
      margin-right: 30px;
      cursor: pointer;
    }
    span.user-box {
      cursor: pointer;
      color: #666666;
      font-size: 14px;
    }
    .el-dropdown-link {
      cursor: pointer;
    }
    .el-dropdown {
      color: #666666;
    }
  }
}

.lang-icon {
  display: inline-block;
  width: 16px;
  height: 11px;
  img {
    width: 100%;
  }
}
.lang {
  border: solid 1px #ffffff;
  padding: 4px 8px;
  border-radius: 2px;
}
.avator {
  width: 32px;
  height: 32px;
  background: #b1e7ff;
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
}
</style>
