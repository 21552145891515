import ajax from '@/api/ajax'
import getConfig from '@/config'

export default {
  save(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/homeBanner/saveHomeBanner',
      method: 'post',
    })
  },

  remove(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/homeBanner/deleteHomeBannerById',
      method: 'post',
    })
  },

  update(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/homeBanner/updateHomeBanner',
      method: 'post',
    })
  },
  findById(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/homeBanner/findHomeBannerById',
      method: 'post',
    })
  },
  queryPage(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/homeBanner/queryHomeBannerPage',
      method: 'post',
    })
  }
}
