const baseUrl = 'http://123.60.75.157/tantan-portal'
// const baseUrl = 'https://cms.carbonebook.com/tantan-portal'
// const baseUrl = 'http://36.138.93.142:8111'
// const baseUrl = 'http://192.168.14.232:8086'
// const baseUrl = 'http://192.168.14.215:8086'

const config = {
  port: 8011,
  name: 'ZEUS',
  room: '', // 静态服务地址
  pathZeus: 'http://192.168.14.99:10002', // 权限中心(未登录重定向到综管平台地址)
  serverApi: baseUrl + '/portal-api', // demo版对应地址
  uploadUrl: baseUrl + '/portal-api/upload/uploadFile',
  // ftpUrl: 'http://media.carbonebook.com',
  ftpUrl: 'http://media2.carbonebook.com',
  secretKey: 'richeninfo'
}
export default config
