import VueCookie from 'vue-cookie'

// 检查登录状态完整性
const checkLogin = callback => {
  const userId = VueCookie.get('userId')
  // const email = VueCookie.get('email')
  const userName = VueCookie.get('userName')
  const token = VueCookie.get('saas-token')
  const phone = VueCookie.get('phone')
  const role = VueCookie.get('role')
  // eslint-disable-next-line standard/no-callback-literal
  if (!token || !userName || !userId || !phone || !role) callback(false)
}

export default checkLogin
