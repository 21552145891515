import ajax from '@/api/ajax'
import getConfig from '@/config'

export default {
  save(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/partner/savePartner',
      method: 'post',
    })
  },

  remove(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/partner/deletePartnerById',
      method: 'post',
    })
  },

  update(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/partner/updatePartner',
      method: 'post',
    })
  },
  findById(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/partner/findPartnerById',
      method: 'post',
    })
  },
  queryPage(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/partner/queryPartnerPage',
      method: 'post',
    })
  }
}
