import ajax from '@/api/ajax'
import getConfig from '@/config'

export default {
  save(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/custom/saveCustom',
      method: 'post',
    })
  },

  remove(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/custom/deleteCustomById',
      method: 'post',
    })
  },

  update(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/custom/updateCustom',
      method: 'post',
    })
  },
  findById(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/custom/findCustomById',
      method: 'post',
    })
  },
  queryPage(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/custom/queryCustomPage',
      method: 'post',
    })
  }
}
