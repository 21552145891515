<template>
  <div class="g-box g-login-box">
    <div class="c">
      <div class="logo"></div>
      <div class="bg"></div>
      <div class="left"></div>
    </div>
    <!--    <img class="u-top-logo" :src="ruleForm.logo || require('../../assets/img/login/lg-login-logo.png')" />-->
    <div class="u-box">
      <h1 class="u-tt">碳探门户管理平台</h1>
      <div class="u-tab"></div>
      <div v-if="tabActive === 'account'">
        <div class="u-it u-account">
          <input
            class="u-it-in"
            v-model="ruleForm.account"
            placeholder="请输入账号"
            @change="check('account')"
            @keyup.enter="login"
          />
          <span class="u-tips">{{ ruleForm.accountTips }}</span>
        </div>
        <div class="u-it u-password">
          <input
            class="u-it-in"
            type="password"
            v-model="ruleForm.password"
            placeholder="请输入密码"
            @change="check('password')"
            @keyup.enter="login"
          />
          <span class="u-tips">{{ ruleForm.passwordTips }}</span>
        </div>
      </div>
      <div class="u-operating">
        <div class="u-el u-remenber">
          <el-checkbox v-model="ruleForm.rememberAccount">记住密码</el-checkbox>
        </div>
        <!-- <div class="u-el u-retrieve" @click="clearCache">遇到登录异常?</div> -->
      </div>
      <el-button class="u-btn" type="primary" @click="login" :loading="loading"
        >登录</el-button
      >
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import api from './../../api'
import bank from './../../router/bank'
import { Notification } from 'element-ui'
// import loadScene from './../../tool/canvas'
import md5 from 'js-md5'
export default Vue.extend({
  name: 'home',
  components: {},
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      formInline: {
        page: {
          total: 0,
          pageSize: 10,
          currentPage: 1
        }
      },
      tabActive: 'account',
      ruleForm: {
        account: '',
        accountTips: '',
        password: '',
        passwordTips: '',
        rememberAccount: false,
        phone: '',
        phoneTips: '',
        verificationCode: '',
        verificationCodeState: false,
        countdown: 60,
        mdPw: '',
        haloImg: '',
        logo: '',
        systemName: ''
      },
      filter: {},
      interval: {},
      homePath: '',
      loading: false
    }
  },
  computed: {
    codeBtnText () {
      if (this.ruleForm.verificationCodeState) {
        return this.ruleForm.countdown
      } else {
        return '获取验证码'
      }
    }
  },
  watch: {},
  beforeCreate () { },
  created () {
    this.init()
  },
  beforeMount () { },
  mounted () {
    // loadScene()
    // this.globalResize(() => {
    //   loadScene()
    // }, 'login2')
  },
  activated () { },
  deactivated () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () {
    clearInterval(this.interval)
    // this.deleteGlobalResize('login')
  },
  destroyed () { },
  methods: {
    init () {
      this.ruleForm.rememberAccount =
        VueCookie.get('saas-rememberAccount') === 'true'
      if (this.ruleForm.rememberAccount) {
        this.ruleForm.account = VueCookie.get('saas-account')
        this.ruleForm.password = '********'
      }
      // api.systemBase.postQuerySystemConfig({}, {
      //   check401Error: false
      // }).then(res => {
      //   this.ruleForm.haloImg = res.data.infoImage
      //   this.ruleForm.logo = res.data.logo
      //   this.ruleForm.systemName = res.data.systemName
      // })
    },
    clearCache () {
      VueCookie.delete('userId')
      VueCookie.delete('email')
      // VueCookie.delete('userName')
      VueCookie.delete('phone')
      VueCookie.delete('orgId') // 组织id
      // VueCookie.set('orgName', res.data.user.orgName || '') // 组织名称
      VueCookie.delete('parkId') // 园区id
      // VueCookie.set('pageUrl', res.data.pageUrl || '')
      VueCookie.delete('role')
      // VueCookie.set('scope', (res.data.scope || []).join(','))
      VueCookie.delete('saas-rememberAccount')
      VueCookie.delete('saas-token')
      VueCookie.delete('saas-account')
      VueCookie.delete('saas-password')

      this.$store.commit('clearUser', {})

      Notification({
        title: '成功',
        message: '清理缓存成功 请重新尝试',
        type: 'success'
      })
    },
    login () {
      this.check()
      if (this.tabActive === 'account') {
        if (
          this.format(this.ruleForm.account) === '' ||
          this.format(this.ruleForm.password) === ''
        ) {
          return
        }
        this.loading = true
        api.systemBase
          .postAuthSalt({
            email: this.ruleForm.account
          })
          .then((rep) => {
            api.systemBase.getAesKey().then(res => {
              this.$store.commit('setUser', {
                ...this.$store.state.user,
                decodeKey: res.data
              })
            }).then(_ => {
              this.loading = false
              const user = {
                loginName: this.ruleForm.account,
                password:
                  this.ruleForm.password === '********'
                    ? VueCookie.get('saas-password')
                    : this.ruleForm.password,
                publicKey: rep.data
                // password: md5((this.ruleForm.password === '********' ? VueCookie.get('password') : this.mdPw) + rep.data)
              }
              api.systemBase.postAuthLogin(user).then((res) => {
                this.afterLogin(res.data)
              }).catch(_ => {
                this.loading = false
              })
            })
          })
      } else {
        if (
          this.format(this.ruleForm.phone) === '' ||
          this.format(this.ruleForm.phone).length !== 11 ||
          isNaN(this.format(this.ruleForm.phone))
        ) {
          return
        }
        api.systemBase
          .postAuthPhoneLogin({
            body: {
              phone: this.ruleForm.phone,
              passcode: this.ruleForm.verificationCode
            }
          })
          .then((res) => {
            this.afterLogin(res)
          })
      }
    },
    afterLogin (data) {
      if (data.userRole != 'BANKUSER') return this.$message.warning('账号或密码错误')
      if (this.ruleForm.rememberAccount) {
        const account = this.ruleForm.account
        const password =
          this.ruleForm.password === '********'
            ? VueCookie.get('saas-password')
            : this.ruleForm.password
        VueCookie.set('saas-account', account || '', 1000 * 60 * 60 * 24 * 7)
        VueCookie.set('saas-password', password || '', 1000 * 60 * 60 * 24 * 7)
      } else {
        VueCookie.delete('saas-account')
        VueCookie.delete('saas-password')
      }
      VueCookie.set('userId', data.id || '')
      VueCookie.set('email', data.loginName || '')
      VueCookie.set('saas-account', data.loginName || '')
      VueCookie.set('saas-token', data.token || '')
      VueCookie.set('phone', data.phone || '')
      VueCookie.set('saas-rememberAccount', this.ruleForm.rememberAccount)
      VueCookie.set('orgId', data.orgId || '') // 组织id
      // VueCookie.set('orgName', res.data.user.orgName || '') // 组织名称
      // VueCookie.set('parkId', res.data.user.parkId || '') // 园区id
      Vue.local.set('ability', (data.sysenum || []).join(','), 1000 * 60 * 60 * 24 * 31)
      // VueCookie.set('pageUrl', res.data.user.pageUrl || '')
      VueCookie.set('role', data.userRole || 'BANKUSER')
      // // VueCookie.set('scope', (res.data.user.scope || ['ZEUS']).join(','))
      // // VueCookie.delete('showMenu', '')
      // VueCookie.delete('isDemoAccount')
      this.$store.commit('setUser', data)

      let routerData = []
      if (data.userRole === 'BANKUSER') {
        routerData = bank
      }
      this.$router.addRoutes(routerData)
      // this.getNavData(() => {
      VueCookie.set('showMenu', '/bannerMerge-list')
      this.$router.push(this.homePath || '/desk')
      // })
    },
    findTreeData (tree = []) {
      return tree.map((item) => {
        if (
          !this.homePath &&
          item.component &&
          !item.path.includes(':') &&
          item.path
        ) { this.homePath = item.path }
        return {
          label: item.title,
          value: item.id,
          name: item.name,
          children: this.findTreeData(item.children)
        }
      })
    },
    getNavData (callback = () => { }) {
      api.systemBase
        .postUserMenu({
          roleId: this.tool.getRoleId(VueCookie.get('role')),
          type: '1'
        })
        .then((res) => {
          Vue.local.set('navData', res.data || [], 1000 * 60 * 60 * 24 * 31)
          callback()
        })
    },
    check (key = '') {
      if (this.tabActive === 'account') {
        if (key === 'account' || !key) this.ruleForm.accountTips = ''
        if (key === 'password' || !key) this.ruleForm.passwordTips = ''
        if (
          this.format(this.ruleForm.account) === '' &&
          (key === 'account' || !key)
        ) {
          this.ruleForm.accountTips = this.$t('Login.userMsg')
        }
        if (
          this.format(this.ruleForm.password) === '' &&
          (key === 'password' || !key)
        ) {
          this.ruleForm.passwordTips = this.$t('Login.pwdMsg')
        }
      } else {
        this.ruleForm.phoneTips = ''
        if (
          this.format(this.ruleForm.phone) === '' &&
          (key === 'phone' || !key)
        ) {
          this.ruleForm.phoneTips = this.$t('Login.phoneMsg')
          return false
        }
        if (
          (this.format(this.ruleForm.phone).length !== 11 ||
            isNaN(this.format(this.ruleForm.phone))) &&
          (key === 'phone' || !key)
        ) {
          this.ruleForm.phoneTips = '手机号错误'
        }
      }
    },
    format (str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    },
    // 过滤用户路由
    getRouter (router, code, ability = '') {
      const nameList = (ability || '')
        .split(',')
        .filter((item) => {
          return item.indexOf(code) === 0
        })
        .map((item) => {
          return item.substring(code.length + 1)
        })

      const filterTree = (list, nameList) => {
        return list
          .filter((item) => {
            return nameList.indexOf(item.name) !== -1 || !item.name
          })
          .map((item) => {
            return {
              ...item,
              children: item.children ? filterTree(item.children, nameList) : []
            }
          })
      }
      return filterTree(router, nameList)
    },
    // 获取验证码
    getVerificationCode () {
      this.check()
      if (
        this.format(this.ruleForm.phone) === '' ||
        this.format(this.ruleForm.phone).length !== 11 ||
        isNaN(this.format(this.ruleForm.phone))
      ) {
        return
      }
      api.systemBase.getAuthPassCode({
        phone: this.ruleForm.phone
      })
      clearInterval(this.interval)
      this.ruleForm.countdown = 60
      this.ruleForm.verificationCodeState = true
      this.interval = setInterval(() => {
        if (this.ruleForm.countdown) {
          this.ruleForm.countdown--
        } else {
          this.ruleForm.countdown = 60
          this.ruleForm.verificationCodeState = false
        }
      }, 1000)
    }
  }
})
</script>

<style lang="scss" scoped>
@import "./../../assets/css/global-variable";
$lg-bg: "../../assets/img/login/lg-bg.png";
$lg-icon-account: "../../assets/img/login/icon-account.png";
$lg-icon-password: "../../assets/img/login/icon-password.png";
.g-login-box {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  //#canvas1{
  //  position:absolute;
  //  top:0;
  //  left:0;
  //  right:0;
  //  bottom:0;
  //  width: 100%;
  //  height: auto;
  //  z-index:0;
  //  background-color:#000;
  //}
  .c {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 30;
    }
    .left {
      width: 632px;
      height: 549px;
      position: absolute;
      top: 50%;
      margin-top: -275px;
      left: 5%;
      background: url($publicPath + "/bg/login-left.svg") no-repeat;
      background-size: 100%;
    }
    .logo {
      width: 140px;
      height: 64px;
      position: absolute;
      top: 10%;
      left: 5%;
      background: url($publicPath + "/bg/logo.svg") no-repeat;
      background-size: 100%;
      z-index: 30;
    }
    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background: #ffffff url($publicPath + "/bg/login-bg.svg") no-repeat;
      background-size: 100%;
    }
    .header {
      width: 100%;
      max-width: 1920px;
      height: 80px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: url($publicPath + "/bg/header.png") no-repeat;
      background-size: 100%;
      z-index: 99;
    }
    .header-title {
      text-align: center;
      line-height: 50px;
      font-size: 24px;
    }
  }
  .u-top-logo {
    width: 156px;
    height: 26px;
    position: absolute;
    left: 30px;
    top: 20px;
  }
  .u-center-logo {
    width: 430px;
    height: 44px;
    position: absolute;
    left: 25%;
    top: 50%;
    transform: translate(-50%);
  }
  .u-nav {
    width: 330px;
    height: 30px;
    position: absolute;
    top: 30px;
    right: 30px;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
  .u-box {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -200px;
    width: 505px;
    height: 400px;
    padding: 32px 60px;
    background: #ffffff;
    border: solid 1px #caffd6;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    box-sizing: border-box;
    z-index: 9999;
    .u-tt {
      font-size: 18px;
      font-weight: 500;
      line-height: 60px;
      margin-top: 20px;
      // margin-bottom:38px;
      color: #206e55;
      text-align: center;
    }
    .u-tab {
      display: flex;
      justify-content: space-around;
      line-height: 50px;
      margin-bottom: 24px;
      font-size: $fs-title-small;
      color: #333333;
      .u-sn {
        cursor: pointer;
        position: relative;
        &::after {
          width: 60px;
          height: 2px;
          content: "";
          display: none;
          background: $fc-theme;
          position: absolute;
          bottom: 8px;
          left: 50%;
          transform: translate(-50%);
        }
        &[data-active] {
          color: $fc-theme;
          text-decoration: none;
          &::after {
            display: block;
          }
        }
      }
    }
    .u-it {
      display: flex;
      position: relative;
      margin-bottom: 25px;
      background: transparent;
      border-radius: 4px;
      // height:40px;
      width: 100%;
      justify-content: space-between;
      position: relative;
      &:after {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        left: 10px;
      }
      &.u-account {
        &:after {
          background: url($lg-icon-account);
        }
      }
      &.u-password {
        &:after {
          background: url($lg-icon-password);
        }
      }
      .u-it-in {
        width: 310px;
        height: 39px;
        border-radius: 0 4px 4px 0;
        margin-left: 40px;
        background-color: transparent;
        outline: none;
        box-shadow: none;
        border: none;
      }
      input.u-it-in::-webkit-input-placeholder {
        color: #333333;
      }
      .u-ver {
        margin-right: 10px;
        flex: 2;
        .u-it-in {
          width: 180px;
        }
      }
      .u-ver-btn {
        flex: 1;
      }
      .u-tips {
        position: absolute;
        top: 45px;
        left: 0;
        color: $fc-danger;
      }
    }
    .u-remenber {
      .el-checkbox {
        color: #333333;
      }
    }
    .u-operating {
      display: flex;
      margin-bottom: 25px;
      justify-content: space-between;
      font-size: $fs-body;
      color: #333333;
      .u-retrieve {
        color: $fc-info;
        cursor: pointer;
      }
    }
    .u-btn {
      width: 100%;
    }
  }
}
</style>

<style></style>
